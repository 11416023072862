import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['addExpenseButton', 'generalExpenseToggle', 'requireMessage', 'serviceInput'];

  add(event) {
    event.preventDefault();
    const templateFields = document.getElementById(event.target.getAttribute('data-template-id') || 'template-fields');
    const target = event.target.parentElement.querySelector('.nested-fields-container') || event.target;
    target.insertAdjacentHTML((event.target === target ? 'beforebegin' : 'beforeend'), templateFields.innerHTML.replace(/new_record/g, new Date().getTime()));
    target.scrollIntoView({ behavior: 'smooth', block: 'center' });
    this.selectService();
  }

  connect() {
    if (this.hasServiceInputTarget) { this.serviceInputPreviousValue = this.serviceInputTarget.value; }
    if (this.data.get('event-id') === '0') {
      this.updateGeneralOptions(document.getElementById('template-fields').content.firstElementChild.querySelector('#expense_category'));
      document.querySelectorAll('select[data-target="admin--expense-fields.expenseTypeInput"]').forEach((expenseTypeInput) => {
        this.updateGeneralOptions(expenseTypeInput);
      });
    }
    if (this.serviceInputTarget.value === 'Videography') { this.serviceChangedToVideography = true; }
  }

  selectService() {
    this.expenseTypeInputs = document.querySelectorAll('[name$="[category]"');
    if (this.serviceInputTarget.value === 'Videography') {
      if (!this.serviceChangedToVideography) {
        this.serviceChangedToVideography = true;
        this.addExpenseButtonTarget.click();
        this.perLotCategoryContainer = this.addExpenseButtonTarget.previousElementSibling;
        this.perLotCategoryContainer.querySelector('#expense_category').selectedIndex = 4;
        this.perLotCategoryContainer.querySelector('[data-target="admin--expense-fields.perLotGroupWrapper"]').hidden = false;
      }
      this.expenseTypeInputs.forEach((expenseTypeInput) => {
        if (expenseTypeInput.options !== undefined) {
          expenseTypeInput.options[4].hidden = false;
          expenseTypeInput.options[5].hidden = false;
        }
      });
    } else {
      this.expenseTypeInputs.forEach((expenseTypeInput) => {
        if (expenseTypeInput.options !== undefined) {
          if (this.serviceInputPreviousValue === 'Videography') { expenseTypeInput.value = null; }
          if (expenseTypeInput.options !== undefined) {
            expenseTypeInput.options[4].hidden = true;
            expenseTypeInput.options[5].hidden = true;
          }
        }
      });
    }
    this.serviceInputPreviousValue = this.serviceInputTarget.value;
  }

  toggleGeneralExpense() {
    if (!this.hasGeneralExpenseToggleTarget) { return; }

    if (this.generalExpenseToggleTarget.checked) {
      this.serviceInputTarget.value = 'Misc';
      this.serviceInputTarget.closest('.form-group.row').hidden = true;
      this.eventLabelField = document.querySelector('[data-target="#event-search-modal, admin--event-search.labelField"]');
      this.eventLabelField.closest('.form-group.row').hidden = true;
      this.eventLabelField.disabled = true;
      this.eventLabelField.value = 'General Expense';
      this.eventIdField = document.querySelector('[data-target="admin--event-search.idField"]');
      this.eventIdField.value = 0;
      this.requireMessageTarget.hidden = true;
      document.querySelectorAll('[data-controller="admin--expense-fields"]').forEach((element) => { element.remove(); });
      this.expenseTypeInput = document.getElementById('template-fields').content.firstElementChild.querySelector('#expense_category');
      this.updateGeneralOptions(this.expenseTypeInput);
      document.querySelector('[data-action="admin--expense-form#add"]').click();
    } else {
      window.location.reload();
    }
  }

  updateGeneralOptions(target) {
    target.remove(4);
    target.add(new Option('Lodging'), 3);
    target.add(new Option('Meals'), 5);
    target.add(new Option('Mileage'), 6);
  }
}
