import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['content', 'loadingIcon', 'modal'];

  connect() {
    $(this.modalTarget).on('hidden.bs.modal', () => {
      this.contentTarget.innerHTML = '';
      this.contentTarget.hidden = true;
      this.loadingIconTarget.hidden = false;
    });
  }

  fetchModal(url) {
    fetch(url, {
      method: 'POST',
      headers: {
        'X-CSRF-Token': document.querySelector("[name='csrf-token']").content,
        'Content-Type': 'application/json'
      }
    })
      .then(response => response.text())
      .then((html) => {
        this.loadingIconTarget.hidden = true;
        this.contentTarget.innerHTML = html;
        this.contentTarget.hidden = false;
        $('[data-toggle="popover"]').popover();
      });
  }

  displayModal(event) {
    const tagName = event.target.tagName;
    if (tagName.includes('INPUT')) return;

    event.preventDefault();
    $(this.modalTarget).modal('show');
    const url = event.currentTarget.dataset.url;
    this.fetchModal(url);
  }
}
